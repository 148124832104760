<template>
  <v-text-field
    v-mask="'##.###.###/####-##'"
    v-bind="$attrs"
    :rules="[...($attrs.rules || []), cnpjInvalido]"
    v-on="$listeners"
  />
</template>

<script>
import { mask } from 'vue-the-mask'
import { cnpjInvalido } from '@/shareds/regrasForm'

export default {
  directives: { mask },
  data: () => ({
    name: 'CampoDeCnpj',
    cnpjInvalido: cnpjInvalido
  })
}
</script>
